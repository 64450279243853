import React, {useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import qs from 'qs';
import {Modal, Group, Button, Textarea, TextInput, Flex} from "@mantine/core";
import styled from "styled-components";
import axios from "axios";
import CustomButton from "../atoms/Button";
import {showNotification, updateNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";


function AddTrainer({getTrainerList}) {
    const [opened, {open, close}] = useDisclosure(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [aboutMe, setAboutMe] = useState("");
    const [vimeo, setVimeo] = useState("");
    const [loading, setLoading] = useState(false);


    const sendMessage = async () => {
        setLoading(true);

        const data = qs.stringify({
            first_name: firstName,
            last_name: lastName,
            email: email,
            about_me: aboutMe,
            vimeo: vimeo ? vimeo : ''
        });

        try {
            console.log(data);
            const response = await axios.post("trainer/add", data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            showNotification({
                id: 'load-data',
                autoClose: false,
                disallowClose: true,
                loading: true,
                title: "Please Wait",
            });

            setTimeout(() => {
                updateNotification({
                    id: 'load-data',
                    color: 'teal',
                    title: "Success",
                    message: "The notification will close in 2 seconds, you can close this notification now",
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000);
            setTimeout(() => {
                close();
                getTrainerList()
            },2000)
        } catch (error) {
            console.log(error);
            showNotification({
                id: "hello-there",
                disallowClose: false,
                autoClose: 5000,
                title: "An error occurred",
                message: "The notification will close in 5 seconds, you can close this notification now",
                color: "red",
                icon: (
                    <IconX
                        className={"icon-xmark-circle"}
                        IconColor={"#fff"}
                        size={28}
                    />
                ),
                className: "my-notification-class",
                style: { backgroundColor: "white" },
                sx: { backgroundColor: "white" },
                loading: false,
            });
        } finally {
            setLoading(false);
        }
    };


    const handleSaveClick = () => {
        if (!firstName) {
            showNotification({
                id: 'hello-there',
                disallowclose: true,
                autoClose: 5000,
                title:"Please Enter The Information First Name Yourself",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        if (!lastName) {
            showNotification({
                id: 'hello-there',
                disallowclose: true,
                autoClose: 5000,
                title:"Please Enter The Information Last Name Yourself",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        if (!email) {
            showNotification({
                id: 'hello-there',
                disallowclose: false,
                autoClose: 5000,
                title:"Please Enter The Information Email Yourself",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        if (!aboutMe) {
            showNotification({
                id: 'hello-there',
                disallowclose: false,
                autoClose: 5000,
                title:"Please Enter The Information About Yourself.",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        if (!vimeo) {
            showNotification({
                id: 'hello-there',
                disallowclose: false,
                autoClose: 5000,
                title:"Please Select Video.",
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: 'red',
                icon: <IconX size="1rem" />,
                className: 'my-notification-class',
                style: { backgroundColor: 'white' },
                sx: { backgroundColor: 'white' },
                loading: false,
            });
        }
        sendMessage();
    };


    const resetFields = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setAboutMe("");
        setVimeo(null)
    };


  return (
    <>
      <ModalX
          opened={opened}
          onClose={close}
          title="Add New Trainer"
          size={"lg"}
      >
        <Flex
         display={"flex"}
         direction={"column"}
         gap={"20px"}
        >
            <TextInput
                placeholder="First Name"
                label="First Name"
                withAsterisk
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
            />
            <TextInput
                placeholder="Last Name"
                label="Last Name"
                withAsterisk
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
            />

            <TextInput
                placeholder="email"
                label="Email"
                withAsterisk
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
            />

            <Textarea
                placeholder="About me"
                label="About me"
                withAsterisk
                autosize
                minRows={2}
                maxRows={4}
                value={aboutMe}
                onChange={(e) => setAboutMe(e.currentTarget.value)}
            />

            <TextInput
                label="Vimeo No"
                placeholder="Vimeo no"
                type="number"
                required
                withAsterisk
                value={vimeo}
                onChange={(e) => setVimeo(e.currentTarget.value)}
            />
            <Flex
            display={"flex"}
            justify={"flex-end"}
            >
                <CustomButton
                    text={"Add"}
                    loading={loading}
                    onClick={() => handleSaveClick()}
                />
            </Flex>
        </Flex>
      </ModalX>

      <Group position="center">
        <Button style={{ backgroundColor: "#9e0c48" }}
        onClick={() => {
            resetFields();
            open();
        }}
        >
          Add Trainer
        </Button>
      </Group>
    </>
  );
}


const ModalX = styled(Modal)`
  .mantine-Modal-header {
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;
  }

  .mantine-Modal-content {
    max-height: calc(100dvh - (4dvh * 2));
  }


`

export default AddTrainer;
