import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "rsuite";
import { useStore } from "./common/store";
import "./App.css";
import "./index.css";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import styled from "styled-components";
import { NavbarSimple } from "./pages/components/sidebar";


function Layout(props) {
  const { state, dispatch } = useStore();
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState();
  const [menuClose, setMenuClose] = useState(``);
  const menuOpenClose = () => {
    if (menuClose === "menu-close") {
      setMenuClose("");
    } else {
      setMenuClose("menu-close");
    }
  };


  const handleLogout = () => {
    localStorage.clear()
    window.location.href = "/login";
  };

  useEffect(() => {
    setMenu(state.user.menu);
    setUserData(state.user);
  }, []);

  return (
    <>
      <div className="wrapper">
        <nav id="sidebar" className={`${menuClose} sidebar js-sidebar`}>
          <NavbarSimple />
        </nav>
        <Container style={{ position: "relative" }}>
          <StyledNavbar>
            <Navbar.Brand href="#">
              <a
                className="sidebar-toggle js-sidebar-toggle"
                onClick={() => menuOpenClose()}
              >
                <i className="hamburger align-self-center"></i>
              </a>
            </Navbar.Brand>

            <Nav pullRight>
              <Nav.Item>
               {state.user.first_name + " " + state.user.last_name}
              </Nav.Item>
              <Nav.Item onClick={handleLogout}>
                Log Out
              </Nav.Item>
            </Nav>
          </StyledNavbar>

          <Main>
            <div className="container-fluid p-0">{props.body}</div>
          </Main>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                  <p className="mb-0 mt-0">admin</p>
                </div>
                <div className="col-6 text-end">
                  <ul className="list-inline"></ul>
                </div>
              </div>
            </div>
          </footer>
        </Container>
      </div>
    </>
  );
}


const Container = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: #fff;
`;
const Main = styled.div`
  height: calc(100vh - 108px);
  background-color: #f2f2f7;
  overflow: auto;
  gap: 10px;
  > div {
    height: 100%;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #9e0c4829;
    border-radius: 5px;
    :hover {
      background-color: #9e0c48;
    }
  }
`;
const StyledNavbar = styled(Navbar)`
  background-color: #9e0c48;
  color: #fff;
  i {
    background-color: #fff;
  }
  i::before {
    background-color: #fff;
  }
  i::after {
    background-color: #fff;
  }
`;


export default Layout;
