import React, { useEffect, useState } from "react";
import { Table, Image } from "antd";
import { Dropdown } from "rsuite";
import PlansModal from "../components/trainerPlans";
import AddTrainer from "../components/addtrainer";
import styled from "styled-components";
import TrainerDelete from "../components/TranierDelete";
import Loader from "../atoms/Loader";
import PayDate from "./PayDate";
import {Flex} from "@mantine/core";
import SalaryAndMinutes from "./SalaryAndMinutes";
import { IconCalendarCheck,IconClock12,IconTrash   } from '@tabler/icons-react';
const TrainerHead = styled.div`
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 5px 20px;
`;
const axios = require("axios").default;
const Trainers = () => {
  const [trainers, setTrainers] = useState([]);
  const [plan, setPlan] = useState(false);
  const [salaryList, setSalaryList] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [trainerList, setTrainerList] = useState({});
  const [isOpened, setIsOpened] = useState(false);
  const[loading,setLoading] = useState(false)
  const[payDateList,setPayDateList] = useState({})

  const showPlanModal = (detail) => {
    setPlan(detail);
  };

  const showSalary = (detail) => {
    setSalaryList(detail);
    setModalVisible(true)
  };


  const showPlanDelete = (record) => {
    setTrainerList(record)
    setIsOpened(true)
  }

  const columns = [
    {
      title: "",
      dataIndex: "image",
      key: "first_name",
      render: (text, record) => (
        <Image width={60} src={record.image_url + "/" + record.image} />
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <>
          <Dropdowned title="Functions">
            <Dropdown.Item
                onClick={() => showPlanModal(record)}
                icon={<IconCalendarCheck  stroke={2} size={"16px"}/>}
            >
              Plans
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => showSalary(record)}
                icon={<IconClock12 stroke={2} size={"16px"} />}
            >
              Salary and Minutes
            </Dropdown.Item>

            <Dropdown.Item
            onClick={() => showPlanDelete(record)}
            icon={<IconTrash  stroke={2} size={"16px"}/>}
            >
              Delete
            </Dropdown.Item>
          </Dropdowned>
        </>
      ),
    },
  ];



  const getTrainerList = async () => {
    setLoading(true);

    try {
      const response = await axios.get('trainer/list');
      setTrainers(response.data.list);
    } catch (error) {
      console.error('Error fetching trainer list:', error);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    getTrainerList()
  },[])




  return (
    <>
      <TrainerHead>
        <h1 className="h3 mt-3">
          <strong>TRAINER</strong> LIST{" "}
        </h1>

      <Flex
        justify={"flex-end"}
        gap={"20px"}
      >
        <PayDate
            setPayDateList={setPayDateList}
            getTrainerList={getTrainerList}
        />

        <AddTrainer
            getTrainerList={getTrainerList}
        />
      </Flex>
      </TrainerHead>
      <p>
        {loading ? (
            <StyledLoad>
              <Loader size={"md"}/>
            </StyledLoad>
        ) : (
            <Table columns={columns} dataSource={trainers} />
        )}
      </p>
      {plan ? (
        <PlansModal plan={plan} visiblePlanCallback={showPlanModal} />
      ) : null}

      {salaryList ? (
          <SalaryAndMinutes
              salaryList={salaryList}
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              getTrainerList={getTrainerList}
              payDateList={payDateList}
          />
      ) : null}

      {trainerList ? (
          <TrainerDelete
              trainerList={trainerList}
              setIsOpened={setIsOpened}
              isOpened={isOpened}
              getTrainerList={getTrainerList}

          />
      ) : null}

    </>
  );
};




const StyledLoad = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`

const Dropdowned = styled(Dropdown)`
  .rs-dropdown-item {
    display: flex;
    align-items: center;
    gap: 6px;
  }

`

export default Trainers;
