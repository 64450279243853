import React, { useEffect, Fragment } from "react";
import "./App.css";
import { Form, Input, Button, Checkbox, notification } from "antd";
import "antd/dist/antd.css";
import {
  Container,
  Header,
  Content,
  Footer,
  FlexboxGrid,
  Panel,
} from "rsuite";
import {useStore } from "./common/store";

import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
///https://codepen.io/andytran/pen/poZKKz
const axios = require("axios").default;

function LoginLayout(props) {

  const { state, dispatch } = useStore();

  const onFinish = (values) => {
    axios
      //.post("http://95.217.219.0:3000/login", values)
        .post("https://qapi.qarint.com/login", values)
        .then((response) => {
        if (response.data.status == "success") {
          dispatch({
            type: "SET_USER_DATA",
            payload: {
              user: response.data.data,
              menu: response.data.data.menu,
            },
          });

          console.log("----huseyin");
          localStorage.setItem("user", JSON.stringify(response.data.data));
          localStorage.setItem("menu", JSON.stringify(response.data.data.menu));
        } else {
          notification["warning"]({
            message: "Login Error",
            description: "Your e-mail address or password is incorrect.",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // console.log(props.body);
  }, []);

  return (
    <>
      <div className="show-fake-browser login-page">
        <Container>
          <Header></Header>
          <Content>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item colspan={12}>
                <Panel bordered>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Şifre"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      wrapperCol={{ offset: 8, span: 16 }}
                    >
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Panel>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Content>
          <Footer></Footer>
        </Container>
      </div>
    </>
  );
}

export default LoginLayout;
