import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Group } from '@mantine/core';
import styled from 'styled-components';
import CustomButton from "./Button";

const CustomModal = forwardRef(({ content, Btn, title, size, height, padding, background, boxshadow, position, centered, onOpen }, ref) => {
    const [opened, { open, close }] = useDisclosure(false);

    useImperativeHandle(ref, () => ({
        open,
        close
    }));

    // Call onOpen function when the modal is opened
    useEffect(() => {
        if (opened && onOpen) {
            onOpen(); // Call onOpen when modal opens
        }
    }, [opened, onOpen]);
    return (
        <>
            <StyledModal
                opened={opened}
                onClose={close}
                title={title}
                size={size ?? "md"}
                height={height}
                padding={padding}
                background={background}
                boxShadow={boxshadow}
                centered={centered}
            >
                {content}
            </StyledModal>

            <Group position={position ?? "center"}>
                <CustomButton
                    text={Btn}
                    onClick={open}
                />
            </Group>
        </>
    );
});

const StyledModal = styled(Modal)`
  .mantine-Modal-header {
    padding: 15px;
    color: #fff;
  }

  .mantine-Modal-body {
    height: ${({ height }) => height};
    padding: ${({ padding }) => padding};
    background: ${({ background }) => background};
    box-shadow: ${({ boxshadow }) => boxshadow};
  }
`;

export default CustomModal;
