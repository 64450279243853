import React, { useEffect, useState, Fragment } from "react";
import { message, Popconfirm, Table, Button, Modal } from "antd";
const axios = require("axios").default;

const TrainerPlans = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [plans, setPlans] = useState([]);

  const handleOk = () => {
    setIsModalVisible(false);
    props.visiblePlanCallback(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.visiblePlanCallback(null);
  };

  const getPlans = () => {

    axios.get(`trainer/plans/${props.plan.id}`)
      .then(function (response) {
        console.log(response.data.list);
        setPlans(response.data.list);

      })
      .catch(function (error) {
        console.log(error)
      });
  };

  const PlanDelete = (id) => {
    axios
      .get(`trainer/plan-delete/${id}`)
      .then(function (response) {
        message.info("Deleted");
        getPlans();
      })
      .catch(function (error) {
          console.log(error)
      });
  };

  useEffect(() => {
    if (props.plan.length !== 0) {
      getPlans();
      setIsModalVisible(true);
    }
  }, []);

  const columns = [
    {
      title: "Plan No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "last_name",
      key: "first_name",
      render: (text, record) => {
        return <>{record.first_name + " " + record.last_name}</>;
      },
    },
    {
      title: "Date",
      dataIndex: "schedule_date",
      key: "schedule_date",
    },
    {
      title: "Start",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "End",
      dataIndex: "end_time",
      key: "end_time",
    },
    {
      title: "Appointment",
      dataIndex: "stulast",
      key: "stulast",
      render: (text, record) => {
        return (
          <>
            {record.stuname} {record.stulast}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <Popconfirm
              placement="topLeft"
              title={"Are you sure ?"}
              onConfirm={() => PlanDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Plans"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        {plans.length > 0 && (
          <Table pagination={true} columns={columns} dataSource={plans} />
        )}
      </Modal>
    </>
  );
};

export default TrainerPlans;
