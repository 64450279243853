import React, {useEffect, useState, Fragment} from "react";
import {useParams} from "react-router-dom";
import {
    Table,
    Image,
    Button,
    Pagination,
    Drawer,
    Form,
    Row,
    Col,
    Input,
    Card,
    Radio,
    PageHeader,
    Statistic,
} from "antd";
import {Dropdown} from "rsuite";
import moment from "moment";
import DetailModal from "../components/detail";
import PlansModal from "../components/plans";
import Extramin from "../components/extramin";
import AddPackage from "../components/AddPackage";
import styled from 'styled-components';
import {
    IconListDetails,
    IconPlusMinus,
    IconCreditCardPay,
    IconTrash,
    IconCalendarEvent,
    IconBrandPaypal,
    IconBrandStripe
} from '@tabler/icons-react';
import Subscription from "../components/subscription";
import {Flex} from "@mantine/core";
import StudentExcel from "./StudentExcel";

const axios = require("axios").default;

const Students = () => {
    const [students, setStudents] = useState([]);
    const [studentDetail, setStudentDetail] = useState();
    const [totalRecord, setTotalRecord] = useState(0);
    let {pageNumber} = useParams();
    let {pagePer} = useParams();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState();
    const [filterVisible, setFilterVisible] = useState(false);
    const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
    const [plan, setPlan] = useState(false);
    const [extramin, setExtramin] = useState(false);
    const [subscriptionList, setSubscriptionList] = useState("");
    const [payment, setPayment] = useState({});
    const [totalPaid, setTotalPaid] = useState(0);
    const [subscriptionModal, setSubscriptionModal] = useState(false)


    const columns = [
        {
            title: "",
            dataIndex: "image",
            key: "image",
            render: (text, record) => (
                <Image width={60} src={record.image_url + "/" + record.image}/>
            ),
        },
        {
            title: "Name",
            dataIndex: "first_name",
            key: "first_name",
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Minutes",
            dataIndex: "remaining_minutes",
            key: "remaining_minutes",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: (text, record) => {
                return (
                    <>
                        {record.price ? (
                            <Statistic
                                title=""
                                prefix="₺"
                                value={record.price}
                                level={5}
                                valueStyle={{fontSize: "12px"}}
                            />
                        ) : null}
                    </>
                );
            },
        },
        {
            title: "Register Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (text, record) => {
                return <>{moment(record.created_at).format("DD-MM-YYYY h:mm")}</>;
            },
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            render: (text, record) => (
                <>
                    <Dropdowned title="Functions" placement="bottomEnd">
                        <Dropdown.Item onClick={() => showTestModal(record)}
                                       icon={<IconBrandPaypal stroke={2} size={"16px"}/>}
                        >
                            Add Subscription
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => showSubscriptionModal(record)}
                                       icon={<IconBrandStripe stroke={2} size={"16px"}/>}
                        >
                            Subscription
                        </Dropdown.Item>


                        <Dropdown.Item onClick={() => showDetailModal(record)}
                                       icon={<IconListDetails stroke={2} size={"16px"}/>}
                        >
                            Detail
                        </Dropdown.Item>
                        {record.price ? (
                            <Dropdown.Item onClick={() => showPlanModal(record)}
                                           icon={<IconCalendarEvent stroke={2} size={"16px"}/>}
                            >
                                Talks and Plan
                            </Dropdown.Item>
                        ) : (
                            <Dropdown.Item disabled
                                           icon={<IconCalendarEvent stroke={2} size={"16px"}/>}
                            >Talks and Plan</Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => showExtraMinModal(record)}
                                       icon={<IconPlusMinus stroke={2} size={"16px"}/>}
                        >
                            Extra Minute Add
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => showExtraMinModal(record)}
                                       icon={<IconCreditCardPay stroke={2} size={"16px"}/>}
                        >
                            Credit Usage Status
                        </Dropdown.Item>

                        <Dropdown.Item
                            icon={<IconTrash stroke={2} size={"16px"}/>}
                        >
                            Delete
                        </Dropdown.Item>
                    </Dropdowned>
                </>
            ),
        },
    ];

    const getUserList = (pageNumber, filters = {}, per) => {
        let params = {
            pageNumber,
            filters,
            pagePer,
        };

        setLoading(true);
        axios.get(`student/list`, {params}).then(function (response) {
            setStudents(response.data.result.list);
            setTotalRecord(response.data.result.items.user_total);
            setTotalPaid(response.data.result.items.paid_total);
            return response;
        })
            .catch(function (error) {
                console.log(error)
            }).finally(() => {
            setLoading(false);
        })
    };

    useEffect(() => {
        console.log("Fetching user list for page number:", pageNumber);
        getUserList(pageNumber);
    }, [pageNumber]);

    const onUserPageChange = (pageNumber, pagePer) => {
        getUserList(pageNumber, filters, pagePer);
    };

    const handleFilter = async (filt) => {
        await setFilters(filt);
        await getUserList(pageNumber, filt, pagePer);
    };


    const showDetailModal = (detail) => {
        setStudentDetail(detail);
    };

    const showPlanModal = (detail) => {
        setPlan(detail);
    };

    const showExtraMinModal = (detail) => {
        setExtramin(detail);
    };

    const showTestModal = (detail) => {
        setPayment(detail);
        setIsPlanModalVisible(true)
    }

    const showSubscriptionModal = (detail) => {
        setSubscriptionList(detail);
        setSubscriptionModal(true)
    }

    const showFilterDrawer = () => {
        setFilterVisible(true);
    };

    const onFilterClose = () => {
        setFilterVisible(false);
    };


    return (
        <>
            <Drawer
                title="Detail Search"
                placement="right"
                onClose={onFilterClose}
                visible={filterVisible}
            >
                <Form
                    layout="vertical"
                    hideRequiredMark
                    name="exam_form"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{flex: 1}}
                    colon={true}
                    onFinish={handleFilter}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={"first_name"} label="Name">
                                <Input placeholder="name"/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="last_name" label="Last Name">
                                <Input placeholder="last_name"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="email" label="Email">
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="country" label="Country">
                                <Input placeholder="Country"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="status" label="Status">
                                <Radio.Group>
                                    <Radio value={1}>Active</Radio>
                                    <Radio value={2}>Passive</Radio>
                                    <Radio value={3}>Delete</Radio>
                                    <Radio value={4}>All</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="pay" label="Pay Status">
                                <Radio.Group>
                                    <Radio value={1}>Paid</Radio>
                                    <Radio value={2}>Not Pay</Radio>
                                    <Radio value={3}>All</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button type="primary" block htmlType="submit">
                        Ara
                    </Button>
                </Form>
            </Drawer>

            <PageHeader>
                <Row>
                    <Statistic
                        title="subscriber"
                        prefix=""
                        value={totalRecord}
                        style={{
                            margin: "0px 32px 0px -16px",
                        }}
                    />
                    <Statistic title="Paid" prefix="₺" value={totalPaid}/>
                </Row>
            </PageHeader>


            <Card
                type="inner"
                title={
                    <h1 className="h3 mb-3">
                        <strong>STUDENTS</strong> LIST ({totalRecord}){" "}
                    </h1>
                }
                extra={
                    <Flex
                        display={"flex"}
                        gap={"50px"}
                        align={"center"}
                    >
                        <StudentExcel/>

                        <Button
                            type="primary"
                            style={{backgroundColor: "#9e0c48", border: "none"}}
                            onClick={showFilterDrawer}
                        >
                            Filter
                        </Button>
                    </Flex>
                }
            >
                {students.length > 0 && (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={students}
                        loading={loading}
                    />
                )}
                {students.length > 0 && (
                    <Pagination
                        defaultCurrent={pageNumber}
                        total={totalRecord}
                        onChange={onUserPageChange}
                        pageSizeOptions={[10, 10000]}
                        showSizeChanger={false}
                    />
                )}
            </Card>

            {studentDetail ? (
                <DetailModal
                    student={studentDetail}
                    visibleCallback={showDetailModal}
                />
            ) : null}

            {plan ? (
                <PlansModal plan={plan} visiblePlanCallback={showPlanModal}/>
            ) : null}

            {extramin ? (
                <Extramin
                    extramin={extramin}
                    visibleExtraMinCallback={showExtraMinModal}
                />
            ) : null}

            {payment ? (
                <AddPackage
                    payment={payment}
                    visiblePlanCallback={showTestModal}
                    isPlanModalVisible={isPlanModalVisible}
                    setIsPlanModalVisible={setIsPlanModalVisible}
                    getUserList={getUserList}

                />
            ) : null}

            {subscriptionList ? (
                <Subscription
                    subscriptionList={subscriptionList}
                    visiblePlanCallback={showSubscriptionModal}
                    setSubscriptionModal={setSubscriptionModal}
                    subscriptionModal={subscriptionModal}
                    getUserList={getUserList}
                />
            ) : null}


        </>
    );
};


const Dropdowned = styled(Dropdown)`
  .rs-dropdown-item {
    display: flex;
    align-items: center;
    gap: 6px;
  }

`

export default Students;
