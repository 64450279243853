import React, {useEffect, useState, Fragment} from "react";
import {useParams} from 'react-router-dom';
import {Pagination,Drawer,Row,Col,Card,Form} from 'antd';
import { Table,Panel, Button, Input } from 'rsuite';
import moment from 'moment';
import SupportModal from "../components/supportDetail";

const axios = require('axios').default;
const Supports = () => {
    const [supports, setSupports] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    let { pageNumber } = useParams();
    const [filterVisible, setFilterVisible] = useState(false);
    const [filters, setFilters] = useState();
    const [detail, setDetail] = useState('');

    const { Column, HeaderCell, Cell } = Table;

    const showFilterDrawer = () => {
        setFilterVisible(true);
    };

    const onFilterClose = () => {
        setFilterVisible(false);
    };

   const showDetailModal = (p1) => {
       setDetail(p1);
   };




    const getList = (pageNumber, filters = {}) => {
        let params = {
            pageNumber,
            filters
        }

        axios.get(`support/list`, {params}).then(function(response) {
            setSupports(response.data.result.list);
            setTotalRecord(response.data.result.items.total);
            return response
        }).catch(function (error) {
            console.log(error)
        });
    }

    useEffect(() => {
        getList(pageNumber);
    },[detail,pageNumber]);

    const onUserPageChange = pageNumber => {
        getList(pageNumber,filters);
    };

    const handleFilter  = async (filt) => {
        await setFilters(filt);
        await getList(pageNumber, filt);
    }

    return(
        <>
            <Drawer title="Detail Search" placement="right"  onClose={onFilterClose} visible={filterVisible}>
                <Form
                    layout="vertical"
                    hideRequiredMark
                    name="exam_form"
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={true}
                    onFinish={handleFilter}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={'first_name'} label="Name" >
                                <Input placeholder="name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="last_name"
                                label="Last Name"
                            >
                                <Input placeholder="last_name" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="subject"
                                label="Title"
                            >
                                <Input placeholder="Title" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button type="primary" block htmlType="submit">Ara</Button>

                </Form>
            </Drawer>
                <Card
                    type="inner"
                    title={<h1 className="h3 mb-3"><strong>SUPPORTS REQUEST</strong> LIST ({totalRecord}) </h1>}
                    extra={<><Button type="primary" onClick={showFilterDrawer}>Filter</Button></>}
                >

                    <Panel>

                    <Table
                        height={1450}
                        data={supports}
                        onRowClick={rowData => {

                        }}
                    >
                        <Column align="center" flexGrow={1}>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="date">
                                {rowData => (
                                    <p>{moment(rowData.date).format('DD-MM-YYYY h:mm')}</p>
                                )}
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>First Name</HeaderCell>
                            <Cell dataKey="first_name" />
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Last Name</HeaderCell>
                            <Cell dataKey="last_name" />
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Title</HeaderCell>
                            <Cell dataKey="subject" />
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Email</HeaderCell>
                            <Cell dataKey="email" />
                        </Column>
                        <Column fixed="right" flexGrow={1}>
                            <HeaderCell>...</HeaderCell>
                            <Cell>
                                {rowData => (
                                    <>
                                        <Button appearance="primary"  style={{ backgroundColor: "#9e0c48", border: "none" }} onClick={() => showDetailModal(rowData)}>Detail and Reply</Button>
                                    </>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                    </Panel>

                    { supports.length > 0 && <Panel><Pagination defaultCurrent={pageNumber} total={totalRecord} onChange={onUserPageChange} recordPerPage={30}/></Panel> }
                    </Card>

                    {
                        detail ? <SupportModal detail = {detail} visibleSupportCallback = {showDetailModal}/> : null
                    }



        </>
    );
}

export default Supports;