import React, { useEffect, useState } from "react";
import {
    Modal,
} from "antd";
import { showNotification,updateNotification } from '@mantine/notifications';
import {IconX, IconTrash, IconCheck} from '@tabler/icons-react';
import { Flex,Table} from '@mantine/core';
import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import Loader from "../atoms/Loader";
import styled from "styled-components";
import CustomButton from "../atoms/Button";
import DataNotFound from "../atoms/DataNotFound";
import ConfirmModal from "../atoms/ConfirmModal";
const axios = require("axios").default;

const Subscription = ({visiblePlanCallback,subscriptionList,subscriptionModal,setSubscriptionModal,getUserList}) => {
    const [loading, setLoading] = useState(false);
    const[substance,setSubstance] = useState([])
    const [opened, setOpened] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [reloadData, setReloadData] = useState(false);


    dayjs.locale('tr');
    const handleOk = () => {
        setSubscriptionModal(false);
        visiblePlanCallback(null);
    };

    const handleCancel = () => {
        setSubscriptionModal(false);
        visiblePlanCallback(null);
    };


    const confirmDelete = () => {
        if (selectedRow) {
            DeleteSubstance(selectedRow);
            setOpened(false);
        }


    };
    const handleDeleteClick = (rowData) => {
       console.log(rowData)
        setSelectedRow(rowData);
        setOpened(true);
    };

    const cancelDelete = () => {
        setOpened(false);
    };




    const getSubscription = () => {
        setLoading(true);
        axios.get(`student/subscriptions/${subscriptionList.user_no}`)
            .then(function (response) {
                setSubstance(response?.data?.data);
                setLoading(false);
                return response;
            })
            .catch(function (error) {
                console.log(error)
            });
    };


    useEffect(() => {
        if (subscriptionList?.length !== 0) {
            getSubscription();
        }
    }, [subscriptionModal]);



    const DeleteSubstance = () => {
        axios.get(`student/subscriptionDelete/${selectedRow.id}`, {
        }).then(function (response) {
            console.log(response);
        }).then(function (response) {
            showNotification({
                id: 'load-data',
                autoClose: false,
                disallowclose: true,
                loading: true,
                title:"Please Wait",
            })
            setTimeout(() => {
                updateNotification({
                    id: 'load-data',
                    color: 'teal',
                    title:"Success",
                    message:"The notification will close in 2 seconds, you can close this notification now",
                    icon: <IconCheck size="1rem" />,
                    autoClose: 1000,
                });
            }, 1000);
            setTimeout(() => {
                getSubscription()
            }, 2000);
        }).catch(function (error) {
            const message = error?.response?.data?.message;
            showNotification({
                id: "hello-there",
                disallowclose: false,
                autoClose: 5000,
                title: message,
                message:"The notification will close in 2 seconds, you can close this notification now",
                color: "red",
                icon: (
                    <IconX
                        className={"icon-xmark-circle"}
                        iconColor={"#fff"}
                        size={28}
                    />
                ),
                className: "my-notification-class",
                style: { backgroundColor: "white" },
                sx: { backgroundColor: "white" },
                loading: false,
            });
        }).finally(() => {

        })

    };

    return (
        <ModalX
            title={`Subscription List (${subscriptionList?.first_name + " " + subscriptionList?.last_name})`}
            visible={subscriptionModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={900}
            confirmLoading={true}
            bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
        >
                {loading ? (
                    <StyledLoad>
                        <Loader size={"sm"} />
                    </StyledLoad>
                ) : (
                        <Flex id={"subscription-wrap-list"} display={"flex"} direction={"column"} gap={"30px"}>
                            <Flex display={"flex"} direction={"column"} gap={"30px"}>
                                <Table
                                 horizontalSpacing="xl"
                                 striped
                                 highlightOnHover
                                >
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Monthly</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {substance.length === 0 ? (
                                                <DataNotFound
                                                    top={"50%"}
                                                    left={"50%"}
                                                    position={"fixed"}
                                                />
                                        ) : (
                                            substance.map((element) => {
                                                const formattedPrice = new Intl.NumberFormat('tr-TR', { style: 'decimal' }).format(element?.price);

                                                return (
                                                    <tr key={element?.id}>
                                                        <td>{formattedPrice}</td>
                                                        <td>{`${element?.monthly} ay ${element?.days} kez`}</td>
                                                        <td>{element?.start_date ? dayjs(element?.start_date).format('YYYY-MM-DD HH:mm') : ''}</td>
                                                        <td>{element?.end_date ? dayjs(element?.end_date).format('YYYY-MM-DD HH:mm') : ''}</td>
                                                        <td>

                                                            <CustomButton
                                                                onClick={() => handleDeleteClick(element)}
                                                                disabled={!element?.status}
                                                                background="transparent"
                                                                hoverBackground="transparent"
                                                                leftIcon={<IconTrash stroke={2} size="22px" />}
                                                                color="red"
                                                                hoverColor="red"
                                                                bordercolor="transparent"
                                                                hoverBordercolor="transparent"
                                                                marginRight={"0"}
                                                                BackgrodDisabled={"transparent"}

                                                            />

                                                            {opened && (
                                                                        <ConfirmModal
                                                                            opened={opened}
                                                                            setOpened={setOpened}
                                                                            onCancel={cancelDelete}
                                                                            onDelete={confirmDelete}
                                                                        />
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    )}
                                    </tbody>
                                </Table>
                            </Flex>
                        </Flex>
                )}
            </ModalX>
    );
};



const StyledLoad = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`
const ModalX = styled(Modal)`
  .ant-modal-content{
    min-height:530px;
  }
`

export default Subscription;
